"use client"

import { Button } from 'antd';
import { useRouter } from 'next/navigation';
import { useMemo, useState } from 'react';
import React, { useEffect, useRef, useCallback } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

// const FloatingTrackContainer = ({ children }: any) => {
//   return (
//     <div 
//       className='absolute right-0 bottom-0 w-[150px] z-[100] h-full bg-gray-500'
//     >
//       {children}
//     </div>
//   )
// };

// const FloatingReactionItemContainer = ({ xPos, children }: any) => {
//   return (
//     <div
//       className="absolute pointer-events-none w-10 h-10 animate-floating-reaction"
//       style={{ left: `${xPos}px`, bottom: '-100px' }}
//     >
//       {children}
//     </div>
//   );
// };

// function getRandomInt(max: any) {
//   return Math.floor(Math.random() * Math.floor(max));
// }

// function FloatingReactionItem({ children }: any) {
//   // randomizing xPos
//   // const xPos = useMemo(() => getRandomInt(70), []);
//   const xPos = 50;
//  return (
//   <FloatingReactionItemContainer xPos={xPos}>
//     {children}
//   </FloatingReactionItemContainer>
//   );
// }

// const initialAnimationObj = { opacity: 0, y: 0, scale: 0.3 };
// const enterAnimationObj = {
//   opacity: [0, 0, 1, 1],
//   y: ['0vh', '-35vh', '-40vh', '-55vh'],
//   scale: [0.3, 0.3, 1, 1],
//   transition: {
//    type: 'spring',
//     duration: 1.5,
//     ease: [0.5, 0.5, 0.4, 0.5],
//     delay: 1,
//     times: [0, 0.1, 0.3, 0.8],
//   },
// };
// const exitAnimationObj = {
//   opacity: 0,
//   y: 0,
//   scale: 0.6,
//   transition: {
//     duration: 0.25,
//   },
// };

// const BigFloatingReactionItemContainer = ({ children, ...props }: any) => {
//   return (
//     <motion.div className='absolute left-[-270px] bottom-[-192px] pointer-events-none' {...props}>{children}</motion.div>
//   )
// }


// const EMOJI_MAP = {
//     fire: '🔥',
// }

// export function GiantReactionMotionWrapper({ motionKey, children }: any) {
//   return (
//     <AnimatePresence>
//       <BigFloatingReactionItemContainer
//         initial={initialAnimationObj}
//         animate={enterAnimationObj}
//         exit={exitAnimationObj}
//         key={motionKey}
//         role='img'
//       >
//       {children}
//       </BigFloatingReactionItemContainer>
//     </AnimatePresence>
//   );
// }


// export const FlyingEmoji = () => {

//     const overlayRef = useRef<any>();
//     const divRef = useRef<any>();

//     const handleRemoveFlyingEmoji = useCallback((node: any) => {
//         if (!overlayRef.current) return;
//         overlayRef.current.removeChild(node);
//     }, []);



//     const handleDisplayFlyingEmoji = useCallback(
//         (emoji:any) => {
//             console.log(emoji);
//             if (!overlayRef.current) {
//                 return;
//             }

//             console.log(`⭐ Displaying flying emoji: ${emoji}`);

//             const node = document.createElement('div');
//             node.appendChild(document.createTextNode(EMOJI_MAP[emoji]));
//             node.className = 'emoji wiggle-1'
//             node.style.transform = `rotate(${-30 + Math.random() * 60}deg)`;
//             overlayRef.current.appendChild(node);

//             node.addEventListener('animationend', (e) => {
//                 console.log("removed")
//                 handleRemoveFlyingEmoji(e.target)
//             });
//         },
//         [handleRemoveFlyingEmoji]
//     );


//     // Remove all event listeners on unmount to prevent console warnings
//     useEffect(() => {
//       if (divRef.current) {
//         const cont = divRef.current
//         setInterval(() => { const time = Math.random() * 200; setTimeout(cont.click(), time)}, 200);
//       }
//         return () => {
//             if (overlayRef.current) {
//                 console.log("Unmounted");
//                 overlayRef.current?.childNodes.forEach((n: any) => {
//                     n.removeEventListener('animationend', handleRemoveFlyingEmoji);
//                 });
//             }
//         };
//     }, []);


//     return (
//         <>
//             <div ref={divRef} style={{
//                 position: 'absolute',
//                 left: '50%',
//                 bottom: '60px',
//                 fontSize: '48px',
//                 lineHeight: '1',
//                 width: '48px',
//                 height: '48px'
//             }} onClick={() => handleDisplayFlyingEmoji('fire')}>
//                 🔥</div>
//             <div className="flying-emojis" ref={overlayRef}>
//                 <style>
//                     {`
//         .flying-emojis {
//           backgroundColor:red;
//           position: fixed;
//           top: 0px;
//           bottom: 0px;
//           left: 0px;
//           right: 0px;
//           overflow: hidden;
//           pointer-events: none;
//           user-select: none;
//           z-index: 99;
//         }

//         .flying-emojis .emoji {
//           position: absolute;
//           left: 50%;
//           bottom: 60px;
//           font-size: 48px;
//           line-height: 1;
//           width: 48px;
//           height: 48px;
//         }

//         .flying-emojis .emoji.wiggle-1 {
//           animation: emerge 3s forwards, wiggle-1 1s ease-in-out infinite alternate;
//         }


//         @keyframes emerge {
//           to {
//             bottom: 85%;
//             opacity: 0;
//           }
//         }

//         @keyframes wiggle-1 {
//           from {
//             margin-left: -20px;
//           }
//           to {
//             margin-left: 50px;
//           }
//         }
//       `}
//                 </style>
//             </div>
//         </>

//     );
// };

export default function Home() {
  // const EMOJI_MAP = ['🔥', '💥', '🎉', '💖', '✨', '😂'];
  // const [emojis, setEmojis] = useState<any[]>([]);

  // useEffect(() => {
  //   // Generate a random number of emojis (e.g., between 3 and 10)
  //   const emojiCount = getRandomInt(7) + 3;

  //   // Generate an array of emoji objects with random positions
  //   const generatedEmojis = Array.from({ length: emojiCount }).map(() => ({
  //     emoji: EMOJI_MAP[getRandomInt(EMOJI_MAP.length)],
  //     xPos: getRandomInt(150), // Assuming the container width is 150px
  //   }));

  //   setEmojis(generatedEmojis);
  // }, []);
  const router = useRouter();

  const startRoulet = () => router.push('/chat-carousel');

  function getRandomInt(max: number) {
    return Math.floor(Math.random() * max);
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen gap-5">
      <main className="flex flex-col items-center gap-5 w-full flex-1 p-4 space-y-4">
        <h1 className="text-center text-3xl md:text-4xl lg:text-5xl mb-4">
          Welcome in TrueCall app!
        </h1>
        <h2>
          Check strangers for truth!
        </h2>
        {/* <FlyingEmoji/> */}
          {/* <FloatingTrackContainer>
          {emojis.map((item, index) => (
        <FloatingReactionItem key={index} xPos={item.xPos}>
          {item.emoji}
        </FloatingReactionItem>
      ))}
          </FloatingTrackContainer> */}
        <Button type='primary' onClick={startRoulet}>Let&apos;s start</Button>
      </main>
    </div>
  );
}
